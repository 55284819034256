import axios from "axios";
import Cookies from "js-cookie";
import {
  getWithExpiry,
  setWithExpiry,
} from "../../utils/localStorageWithExpiry";
import * as types from "../types";

export const sendLoginData = (login, password) => {
  return async (dispatch) => {
    const body = { login, password };

    await axios
      .post("/auth/login", body)
      .then((response) => {
        const token = response.data.access_token;

        if (process.env.NODE_ENV === "development") {
          Cookies.set("my-jwt-token", token);
        }
        setWithExpiry("token", token, 5 * 24 * 60 * 60 * 1000);

        dispatch({ type: types.SET_USER_DATA, token });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.LOGIN_ERROR, detail: "login error" });
      });
  };
};

export const authMe = () => {
  return async (dispatch) => {
    await axios
      .get("/auth/me")
      .then((response) => {
        const token = getWithExpiry("token");
        dispatch({ type: types.SET_USER_DATA, token });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.AUTH_ME_ERROR });
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    await axios
      .post("/auth/logout")
      .then((response) => {
        dispatch({ type: types.LOGOUT });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.LOGOUT_ERROR, detail: "logout error" });
      });
  };
};
