import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import { makeStyles } from "@material-ui/core/styles";
import classes from "./MerchRoutesTable.module.scss";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  innerTable: {
    boxShadow:
      "inset 0 4px 8px -5px rgb(50 50 50 / 75%), inset 0 -4px 8px -5px rgb(50 50 50 / 75%)",
  },
});

export const MerchRoutesRow = ({
  row,
  index,
  onEditHandle,
  onDeleteHandle,
}) => {
  const [open, setOpen] = React.useState(false);
  const styles = useRowStyles();

  const createMapLink = (points) => {
    const link = points
      .map(
        (item, index) =>
          `${index + 1}_${item.location?.lat || "0.0"}_${
            item.location?.long || "0.0"
          }_${item.network}`
      )
      .join("~");

    return `/maps/?pt=${link}&from=admin`;
  };

  return (
    <>
      <TableRow className={styles.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.number}</TableCell>
        <TableCell align="left">{row.region}</TableCell>
        <TableCell align="left">{row.bu}</TableCell>
        <TableCell align="left">{row.group_leader.name}</TableCell>
        <TableCell align="left">{row.group_leader.phone}</TableCell>
        <TableCell align="left">{row.route_stores.length}</TableCell>
        <TableCell>
          <a
            href={createMapLink(row.route_stores)}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            Перейти
          </a>
        </TableCell>
        <TableCell align="left">
          <div
            className={classes.flex_cell}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {row.registered_count}
            <div className={classes.actions}>
              <Button
                onClick={() => onEditHandle(row.id)}
                classes={{
                  root: classes.btn,
                }}
              >
                <CreateIcon />
              </Button>
              <Button
                onClick={() => onDeleteHandle(row.id)}
                classes={{
                  root: classes.btn,
                }}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow className={styles.innerTable}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography gutterBottom component="div">
                Точки
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Город</TableCell>
                    <TableCell>Адрес</TableCell>
                    <TableCell>Ship to</TableCell>
                    <TableCell>Сеть</TableCell>
                    <TableCell>На карте</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.route_stores.map((routeRow, index) => (
                    <TableRow key={index}>
                      <TableCell>{routeRow.city}</TableCell>
                      <TableCell>{routeRow.address}</TableCell>
                      <TableCell component="th" scope="row">
                        {routeRow.ship_to}
                      </TableCell>
                      <TableCell>{routeRow.network}</TableCell>
                      <TableCell>
                        <a
                          href={`/maps/?pt=${index + 1}_${
                            routeRow.location?.lat || "0.0"
                          }_${routeRow.location?.long || "0.0"}_${
                            routeRow.network
                          }&from=admin`}
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >{`${routeRow.location?.lat || "0.0"},${
                          routeRow.location?.long || "0.0"
                        }`}</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
