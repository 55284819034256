import React from "react";
import DG from "2gis-maps";
import { useLocation } from "react-router-dom";

const iconUrls = {
  PYATEROCHKA:
    "https://w7.pngwing.com/pngs/967/172/png-transparent-pyaterochka-saratov-x5-retail-group-mass-market-retailing-magnet-miscellaneous-text-trademark.png",
  MAGNIT: "https://logosklad.ru/photo/logos/600/1550684018.jpg",
  PEREKRESTOK:
    "https://hsto.org/getpro/moikrug/uploads/company/284/360/376/logo/596b362963460ba36bd0bee04591d795.png",
  LENTA: "https://logosklad.ru/photo/logos/227/1488545947.jpg",
  KARUSEL: "https://logosklad.ru/photo/logos/578/1532440894.jpg",
  DIXY: "https://logosklad.ru/photo/logos/332/1310932925.jpg",
};

const Map = () => {
  const { search } = useLocation();

  const queryObj = search
    .replace("?", "")
    .split("&")
    .map((item) => item.split("="))
    .reduce((result, current) => ({ ...result, [current[0]]: current[1] }), {});

  const points = queryObj.pt.split("~").map((point) => point.split("_"));
  const pointsObj = points.map((item) => {
    return {
      number: item[0],
      location: [item[1], item[2]],
      network: item[3],
    };
  });

  const label = queryObj.from === "bot" ? "Маршрут" : "Точка";

  React.useEffect(() => {
    let map, group, icon;

    map = DG.map("map", {
      center: [54.98, 82.89],
      zoom: 13,
    });

    const markers = pointsObj.map((point, index) => {
      if (iconUrls[point.network]) {
        icon = DG.icon({
          iconUrl: iconUrls[point.network],
          iconSize: [36, 36],
        });
      } else {
        icon = DG.icon({
          iconUrl:
            "https://maps.api.2gis.ru/2.0/img/DGCustomization__marker.png",
          iconSize: [23, 36],
        });
      }

      return DG.marker([...point.location], {
        icon,
      })
        .addTo(map)
        .bindLabel(`${label} №${point.number}`, {
          static: true,
        });
    });

    group = DG.featureGroup([...markers]);
    map.fitBounds(group.getBounds());
    group.addTo(map);
    group.on("click", function (e) {
      map.setView([e.latlng.lat, e.latlng.lng]);
    });

    return () => map.remove();
  }, []);

  return (
    <div className="map">
      <MapWrapper />
    </div>
  );
};

const MapWrapper = React.memo(
  () => {
    return <div id="map" style={{ width: "100vw", height: "100vh" }}></div>;
  },
  () => true
);

export default Map;
