import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import MainContainer from "../MainContainer/MainContainer";
import { ModalWindow } from "../UI/ModalWindow/ModalWindow";
import { MerchRouteForm } from "./MerchRouteForm/MerchRouteForm";
import { CustomSelect } from "../UI/CustomSelect";
import { MerchRoutesTable } from "./MerchRoutesTable/MerchRoutesTable";

import { authMe } from "../../store/actions/loginActions";
import {
  createRoute,
  fetchBU,
  fetchRegions,
  fetchRoutes,
  deleteRoute,
  clearCurrentRoute,
  updateRoute,
  fetchMerchDay,
  uploadFileRoutes,
} from "../../store/actions/merchActions";

import classes from "./MerchRoutes.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export const MerchRoutes = () => {
  const styles = useStyles();

  const fileInput = React.useRef();

  const { pathname } = useLocation();
  const merchDayId = pathname.split("/")[2];

  const dispatch = useDispatch();
  const { currentMerchDay, routes, regions, bu, loading } = useSelector(
    ({ merch }) => merch
  );

  React.useEffect(() => {
    dispatch(authMe());
    dispatch(fetchMerchDay(merchDayId));
    dispatch(fetchRoutes(merchDayId));
    dispatch(fetchRegions(merchDayId));
    dispatch(fetchBU(merchDayId));
  }, []);

  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    React.useState(false);

  const [editedId, setEditedId] = React.useState(null);
  const [deletedId, setDeletedId] = React.useState(null);

  const [regionSelectValue, setRegionSelectValue] = React.useState("");
  const [BUSelectValue, setBUSelectValue] = React.useState("");

  const [groupedRoutes, setGroupedRoutes] = React.useState([]);

  const changeRegionHandle = (event) => {
    setRegionSelectValue(event.target.value);
  };

  const changeBUHandle = (event) => {
    setBUSelectValue(event.target.value);
  };

  React.useEffect(() => {
    dispatch(fetchRoutes(merchDayId, regionSelectValue, BUSelectValue));
  }, [regionSelectValue, BUSelectValue]);

  const groupRoutesByRegion = () => {
    const regionsObj = routes.reduce(function (result, current) {
      result[current.region] = result[current.region] || [];
      result[current.region].push(current);
      return result;
    }, Object.create(null));

    const regionsArr = Object.entries(regionsObj).map((e) => ({
      title: e[0],
      routes: e[1],
    }));

    setGroupedRoutes(regionsArr);
  };

  React.useEffect(() => {
    groupRoutesByRegion();
  }, [routes]);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setEditedId(null);
    dispatch(clearCurrentRoute());
    setOpenModal(false);
  };

  const handleClickOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    fileInput.current = null;
  };

  const onEditHandle = (id) => {
    setEditedId(id);

    handleClickOpenModal();
  };

  const openConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
  };
  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };
  const confirmDelete = () => {
    dispatch(deleteRoute(merchDayId, deletedId));
    closeConfirmDeleteModal();
  };

  const onDeleteHandle = (id) => {
    openConfirmDeleteModal();
    setDeletedId(id);
  };

  const handleSubmit = (values) => {
    if (editedId) {
      dispatch(updateRoute(merchDayId, editedId, values));
    } else {
      dispatch(createRoute(merchDayId, values));
    }
    handleCloseModal();
  };

  const onFileUpload = () => {
    handleClickOpenConfirmModal();
  };

  const handleConfirmFileUpload = () => {
    let formData = new FormData();
    formData.append("file", fileInput.current?.files[0]);

    dispatch(uploadFileRoutes(merchDayId, formData));
  };

  React.useEffect(() => {
    if (!loading && openConfirmModal) {
      handleCloseConfirmModal();
    }
  }, [loading]);

  return (
    <MainContainer>
      <div className={classes.routes}>
        <div className={classes.content}>
          <div className={classes.header}>
            <Link to="/merch" className={classes.back_link}>
              <ArrowBackIcon />
              <span>Назад к мерч дням</span>
            </Link>
            <div className={classes.btn_wrapper}>
              <Button
                variant="contained"
                className={`${classes.btn} ${classes.btn_download}`}
                startIcon={
                  <GetAppIcon
                    classes={{
                      root: classes.btn_icon,
                    }}
                  />
                }
                disabled
              >
                <span className={classes.btn_text}>Скачать записи</span>
              </Button>
              <div className={classes.btn}>
                <input
                  className={styles.input}
                  id="contained-button-file"
                  type="file"
                  ref={fileInput}
                  onChange={onFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    className={`${classes.btn} ${classes.btn_upload}`}
                    startIcon={
                      <PublishIcon
                        classes={{
                          root: classes.btn_icon,
                        }}
                      />
                    }
                  >
                    <span className={classes.btn_text}>Импорт из Excel</span>
                  </Button>
                </label>
              </div>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={handleClickOpenModal}
                startIcon={
                  <AddIcon
                    classes={{
                      root: classes.btn_icon,
                    }}
                  />
                }
              >
                <span className={classes.btn_text}>Добавить</span>
              </Button>
            </div>
          </div>
          <section className={classes.table_wrapper}>
            <div className={classes.title}>
              Маршруты мерч дня{" "}
              {currentMerchDay.date &&
                new Date(currentMerchDay.date).toLocaleDateString("ru-Ru")}
            </div>
            {routes.length > 0 || regionSelectValue || BUSelectValue ? (
              <>
                <div className={classes.filters}>
                  <CustomSelect
                    options={regions}
                    title="Регион"
                    label="Регион"
                    value={regionSelectValue}
                    setValue={changeRegionHandle}
                  />
                  <CustomSelect
                    options={bu}
                    title="BU"
                    label="BU"
                    value={BUSelectValue}
                    setValue={changeBUHandle}
                  />
                </div>
                {groupedRoutes.length === 0 ? (
                  <div className={classes.text}>
                    Для данного фильтра нет маршрутов. Нажмите кнопку “Добавить”
                  </div>
                ) : (
                  groupedRoutes?.map((group) => (
                    <MerchRoutesTable
                      key={group.title}
                      routes={group}
                      onDeleteHandle={onDeleteHandle}
                      onEditHandle={onEditHandle}
                    />
                  ))
                )}
              </>
            ) : (
              <div className={classes.no_cards}>
                <div className={classes.text}>
                  Для добавления маршрута нажмите кнопку “Добавить”
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
      <ModalWindow
        title={editedId ? "Редактировать маршрут" : "Новый маршрут"}
        isOpen={openModal}
        handleClose={handleCloseModal}
      >
        <MerchRouteForm
          onSubmit={handleSubmit}
          onCancel={handleCloseModal}
          editedId={editedId}
        />
      </ModalWindow>
      <ModalWindow
        title={"Импортировать маршруты"}
        isOpen={openConfirmModal}
        handleClose={handleCloseConfirmModal}
      >
        <div className={classes.confirm_modal}>
          <div className={classes.text}>
            При импорте маршрутов из Excel все старые маршруты и записи на них
            будут удалены. Вы действительно хотите продолжить?
          </div>
          <div className={classes.btn_wrapper}>
            {loading && <CircularProgress />}
            <Button
              classes={{
                root: classes.cancel_btn,
              }}
              variant="contained"
              type="button"
              onClick={handleCloseConfirmModal}
              disableElevation
              disabled={loading}
            >
              Отмена
            </Button>
            <Button
              classes={{
                root: classes.submit_btn,
              }}
              variant="contained"
              color="primary"
              type="button"
              onClick={handleConfirmFileUpload}
              disableElevation
              disabled={loading}
            >
              Отправить
            </Button>
          </div>
        </div>
      </ModalWindow>
      <ModalWindow isOpen={showConfirmDeleteModal} title={"Удалить маршрут"}>
        <div className={classes.confirm_modal}>
          <div className={classes.text}>
            Вы действительно хотите удалить маршрут?
          </div>
          <div className={classes.btn_wrapper}>
            <Button
              classes={{
                root: classes.cancel_btn,
              }}
              variant="contained"
              type="button"
              onClick={closeConfirmDeleteModal}
              disableElevation
            >
              Отмена
            </Button>
            <Button
              classes={{
                root: classes.submit_btn,
              }}
              variant="contained"
              color="primary"
              type="button"
              onClick={confirmDelete}
              disableElevation
            >
              Удалить
            </Button>
          </div>
        </div>
      </ModalWindow>
    </MainContainer>
  );
};
