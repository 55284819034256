import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./index.scss";
import App from "./components/app/App";
import { Provider } from "react-redux";
import store from "./store";
import { getWithExpiry } from "./utils/localStorageWithExpiry";

if (process.env.REACT_APP_BACKEND_ADDR) {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ADDR;
} else {
  axios.defaults.baseURL = "https://merchdays.appvelox.ru/api";
}

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  function (config) {
    if (
      process.env.NODE_ENV === "development" &&
      typeof window !== "undefined"
    ) {
      const token = getWithExpiry("token");

      const tokenType = "bearer";
      config.headers.Authorization = tokenType + " " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Helmet>
          <title>Чат-бот для Мерч дней. Административный интерфейс.</title>
          <meta
            name="title"
            content="Чат-бот для Мерч дней. Административный интерфейс."
          />
        </Helmet>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
