import React from "react";
import { Form, Field } from "react-final-form";
import { Button } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import classes from "./MerchDayForm.module.scss";
import { fetchMerchDay } from "../../../store/actions/merchActions";
import { useDispatch, useSelector } from "react-redux";

const DatePickerAdapter = ({
  input: { name },
  inputProps: { onChange, value },
  ...rest
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        placeholder=""
        minDate={new Date()}
        format="dd.MM.yyyy"
        value={value}
        onChange={onChange}
        name={name}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MerchDayForm = ({ editedId, onSubmit }) => {
  const dispatch = useDispatch();
  const { currentMerchDay } = useSelector(({ merch }) => merch);

  const [date, setDate] = React.useState(null);
  const [registration_start_date, setRegistrationDate] = React.useState(null);

  React.useEffect(() => {
    if (editedId) {
      dispatch(fetchMerchDay(editedId));
      currentMerchDay.date && setDate(new Date(currentMerchDay.date));
      currentMerchDay.registration_start_date && setRegistrationDate(new Date(currentMerchDay.registration_start_date));
    }
  }, []);

  React.useEffect(() => {
    currentMerchDay.date && setDate(new Date(currentMerchDay.date));
  }, [currentMerchDay.date]);

  React.useEffect(() => {
    currentMerchDay.registration_start_date && setRegistrationDate(new Date(currentMerchDay.registration_start_date));
  }, [currentMerchDay.registration_start_date]);

  return (
    <div>
      <Form
        onSubmit={() => onSubmit({ date, registration_start_date })}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.input_item}>
                <label className={classes.subtitle}>
                  Выберете дату для мерч дня
                </label>
                <Field
                  name="date"
                  component={DatePickerAdapter}
                  value={date}
                  inputProps={{
                    value: date,
                    onChange: (event) => setDate(event),
                  }}
                  required
                />
              </div>
              <div className={classes.input_item}>
                <label className={classes.subtitle}>
                  Выберете дату начала записи
                </label>
                <Field
                  name="date"
                  component={DatePickerAdapter}
                  value={registration_start_date}
                  inputProps={{
                    value: registration_start_date,
                    onChange: (event) => setRegistrationDate(event),
                  }}
                  required
                />
              </div>
              <div className={classes.button_wrapper}>
                <Button
                  classes={{
                    root: classes.submit_btn,
                  }}
                  variant="contained"
                  type="submit"
                  disableElevation
                  disabled={!date}
                >
                  Отправить
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
