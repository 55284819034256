export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const AUTH_ME_ERROR = "AUTH_ME_ERROR";

export const SET_LOADING = "SET_LOADING";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_NEW_USERS_STATS = "SET_NEW_USERS_STATS";
export const SET_TOTAL_USERS_STATS = "SET_TOTAL_USERS_STATS";
export const SET_EVENTS_DATA = "SET_EVENTS_DATA";

export const FETCH_MERCH_DAYS = "FETCH_MERCH_DAYS";
export const CREATE_MERCH_DAY = "CREATE_MERCH_DAY";
export const FETCH_MERCH_DAY = "FETCH_MERCH_DAY";
export const UPDATE_MERCH_DAY = "FETCH_MERCH_UPDATE";

export const FETCH_ROUTES = "FETCH_ROUTES";
export const CREATE_ROUTE = "CREATE_ROUTE";
export const FETCH_ROUTE = "FETCH_ROUTE";
export const DELETE_ROUTE = "DELETE_ROUTE";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
export const UPLOAD_FILE_ROUTES = "UPLOAD_FILE_ROUTES";

export const FETCH_REGIONS = "FETCH_REGIONS";
export const FETCH_BU = "FETCH_BU";

export const CLEAR_CURRENT_ROUTE = "CLEAR_CURRENT_ROUTE";
export const CLEAR_CURRENT_MERCH_DAY = "CLEAR_CURRENT_MERCH_DAY";
