import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import classes from "./MerchRoutesTable.module.scss";
import { MerchRoutesRow } from "./MerchRoutesRow";

export const MerchRoutesTable = ({ routes, onEditHandle, onDeleteHandle }) => {
  return (
    <div className={classes.table_wrapper}>
      <div className={classes.title}>{routes.title}</div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width={20} align="center"></TableCell>
            <TableCell width={100} align="left">
              Номер
            </TableCell>
            <TableCell width={130} align="left">
              Регион
            </TableCell>
            <TableCell width={150} align="left">
              BU
            </TableCell>
            <TableCell width={300} align="left">
              Лидер группы
            </TableCell>
            <TableCell width={300} align="left">
              Телефон лидера
            </TableCell>
            <TableCell width={250} align="left">
              Кол-во точек
            </TableCell>
            <TableCell width={250} align="left">
              На карте
            </TableCell>
            <TableCell width={300} align="left">
              Кол-во записавшихся
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {routes.routes.map((row, index) => (
            <MerchRoutesRow
              key={index}
              row={row}
              index={index}
              onEditHandle={onEditHandle}
              onDeleteHandle={onDeleteHandle}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
