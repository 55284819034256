import React from "react";
import { Field } from "react-final-form";
import { CustomSelect } from "../../UI/CustomSelect";
import { CustomField } from "./CustomField";

import classes from "./MerchRouteForm.module.scss";

const selectOptions = [
  "PYATEROCHKA",
  "MAGNIT",
  "PEREKRESTOK",
  "LENTA",
  "KARUSEL",
  "DIXY",
  "OTHER",
];

const CustomFormSelect = ({ inputProps: { value, onChange, name } }) => {
  return (
    <CustomSelect
      options={selectOptions}
      title="Сеть"
      value={value}
      setValue={onChange}
      name={name}
    />
  );
};

export const PointBlock = ({
  index,
  point,
  onPointFieldChange,
  deletePointHandle,
}) => {
  const [selectValue, setSelectValue] = React.useState(point.network);

  React.useEffect(() => {
    setSelectValue(point.network);
  }, [point.network]);

  return (
    <div className={classes.points}>
      <div className={classes.points_title}>
        Точка №{index + 1}
        <span onClick={() => deletePointHandle(index)}>Удалить</span>
      </div>
      <div className={classes.input_item}>
        <p className={classes.subtitle}>Ship to</p>
        <CustomField
          placeholder="Ship to"
          name="route_stores.ship_to"
          value={point.ship_to}
          onChange={(event) => onPointFieldChange(index, event)}
        />
      </div>
      <div className={classes.input_item}>
        <p className={classes.subtitle}>Город</p>
        <CustomField
          placeholder="Город"
          name="route_stores.city"
          value={point.city}
          onChange={(event) => onPointFieldChange(index, event)}
        />
      </div>
      <div className={classes.input_item}>
        <p className={classes.subtitle}>Сеть</p>
        <Field
          component={CustomFormSelect}
          name="route_stores.network"
          variant="outlined"
          classes={{
            root: classes.input,
          }}
          inputProps={{
            value: selectValue,
            onChange: (event) => {
              setSelectValue(event.target.value);
              onPointFieldChange(index, event);
            },
            name: "route_stores.network",
          }}
          required
        />
      </div>
      <div className={classes.input_item}>
        <p className={classes.subtitle}>Адрес</p>
        <CustomField
          placeholder="Адрес"
          name="route_stores.address"
          value={point.address}
          onChange={(event) => onPointFieldChange(index, event)}
        />
      </div>
      <div className={classes.input_item}>
        <p className={classes.subtitle}>Координаты</p>
        <div className={classes.coordinates}>
          <div className={classes.coordinates_item}>
            <p className={classes.subtitle}>Широта</p>
            <CustomField
              placeholder="Широта"
              name="route_stores.location.lat"
              value={point.location?.lat === 0 ? "0.0" : point.location.lat}
              onChange={(event) => onPointFieldChange(index, event)}
            />
          </div>
          <div className={classes.coordinates_item}>
            <p className={classes.subtitle}>Долгота</p>
            <CustomField
              placeholder="Долгота"
              name="route_stores.location.long"
              value={point.location?.long === 0 ? "0.0" : point.location.long}
              onChange={(event) => onPointFieldChange(index, event)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
