import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    backgroundColor: "#ffffff",
  },
  root: {
    "&.MuiFormLabel-root.Mui-focused": {
      display: "none",
    },
    "&.MuiFormLabel-root.MuiFormLabel-filled": {
      display: "none",
    },
  },
}));

export function CustomSelect({ options, title, label, name, value, setValue }) {
  const classes = useStyles();

  const handleChange = (event) => {
    setValue(event);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        id="select-outlined-label"
        className={!label ? classes.root : ""}
      >
        {title}
      </InputLabel>
      <Select
        className={classes.select}
        labelId="select-outlined-label"
        id="select-outlined"
        value={value}
        onChange={handleChange}
        label={label}
        name={name}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
