import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { mainReducer } from "./mainReducer";
import { merchReducer } from "./merchReducer";

export default combineReducers({
  login: loginReducer,
  main: mainReducer,
  merch: merchReducer,
});
