import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import MainContainer from "../MainContainer/MainContainer";
import {
  fetchNewUserStats,
  fetchTotalUserStats,
  fetchEvents,
} from "../../store/actions/mainActions";

import classes from "./Statistics.module.scss";
import { authMe } from "../../store/actions/loginActions";

const cx = classNames.bind(classes);

function getFormatDate(date) {
  return date.toISOString().substring(0, 10);
}

const tabs = ["Вчера", "Сегодня", "Неделя", "Месяц"];

const Statistics = () => {
  const dispatch = useDispatch();
  const { newUsers, totalUsers, events } = useSelector((store) => store.main);

  const [activeTab, setActiveTab] = React.useState(0);

  const now = new Date();
  const yesterday = new Date(new Date().setDate(now.getDate() - 1));
  const weekAgo = new Date(new Date().setDate(now.getDate() - 7));
  const monthAgo = new Date(new Date().setDate(now.getDate() - 30));
  const periods = [yesterday, now, weekAgo, monthAgo];

  React.useEffect(() => {
    dispatch(authMe());

    dispatch(fetchTotalUserStats());
    dispatch(fetchEvents());
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchNewUserStats(getFormatDate(periods[activeTab]), getFormatDate(now))
    );
  }, [activeTab]);

  return (
    <MainContainer>
      <div className={classes.wrapper}>
        {/* Новые пользователи  */}
        <section className={classes.new}>
          <div className={classes.title}>Новые пользователи чат-бота</div>
          <ul className={classes.new__tabs}>
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={cx({
                  new__tab: true,
                  active: index === activeTab,
                })}
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={425} align="center">
                  Платформа
                </TableCell>
                <TableCell width={425} align="center">
                  Количество
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Workplace</TableCell>
                <TableCell align="center">{newUsers?.workplace || 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>

        {/* Всего пользователей  */}
        <section className={classes.total}>
          <div className={classes.title}>Пользователи чат-бота</div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={425} align="center">
                  Платформа
                </TableCell>
                <TableCell width={425} align="center">
                  Количество
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">Workplace</TableCell>
                <TableCell align="center">
                  {totalUsers?.workplace || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>

        {/* Популярные действия  */}
        <section className={classes.events}>
          <div className={classes.title}>Популярные действия</div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width={81} align="center">
                  №
                </TableCell>
                <TableCell width={344} align="left">
                  Действие
                </TableCell>
                <TableCell width={220} align="center">
                  Workplace
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events
                ? events.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{row.event}</TableCell>
                      <TableCell width={220} align="center">
                        {row.hits_count}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </section>
      </div>
    </MainContainer>
  );
};

export default Statistics;
