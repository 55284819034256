import React from "react";
import { Field } from "react-final-form";

import CustomTextField from "../../UI/CustomTextField";

import classes from "./MerchRouteForm.module.scss";

export const CustomField = ({ placeholder, name, value, onChange }) => {
  return (
    <Field
      placeholder={placeholder}
      component={CustomTextField}
      name={name}
      variant="outlined"
      classes={{
        root: classes.input,
      }}
      inputProps={{
        value: value,
        onChange: onChange,
      }}
      fullWidth
      required
    />
  );
};
