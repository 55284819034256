import React from "react";
import { useLocation } from "react-router";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { CustomField } from "./CustomField";
import { PointBlock } from "./PointBlock";
import { ModalWindow } from "../../UI/ModalWindow/ModalWindow";

import { Button } from "@material-ui/core";
import { fetchRoute } from "../../../store/actions/merchActions";

import classes from "./MerchRouteForm.module.scss";

export const MerchRouteForm = ({ onSubmit, onCancel, editedId }) => {
  const { pathname } = useLocation();
  const merchDayId = pathname.split("/")[2];

  const dispatch = useDispatch();
  const { currentRoute } = useSelector(({ merch }) => merch);

  const [formData, setFormData] = React.useState({});
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    React.useState(false);
  const [deletedId, setDeletedId] = React.useState(null);

  React.useEffect(() => {
    if (editedId) {
      dispatch(fetchRoute(merchDayId, editedId));
    }
  }, []);

  React.useEffect(() => {
    setFormData(currentRoute);

    if (currentRoute.route_stores) {
      setPoints({ route_stores: currentRoute.route_stores });
    }
  }, [currentRoute]);

  const [points, setPoints] = React.useState({
    route_stores: [
      {
        ship_to: "",
        city: "",
        network: "",
        address: "",
        location: { lat: 0.0, long: 0.0 },
      },
    ],
  });

  const onPointFieldChange = (index, { target }) => {
    const pointsValues = points;

    const [, fieldName, coord] = target.name.split(".");

    if (fieldName === "location") {
      if (!isNaN(+target.value)) {
        pointsValues.route_stores[index][fieldName][coord] = target.value;
        pointsValues.route_stores[index][fieldName][coord] = target.value;
      }
    } else {
      pointsValues.route_stores[index][fieldName] = target.value;
    }

    setPoints(pointsValues);
  };

  const addPointHandle = () => {
    setPoints({
      route_stores: [
        ...points.route_stores,
        {
          ship_to: "",
          city: "",
          network: "",
          address: "",
          location: { lat: 0, long: 0 },
        },
      ],
    });
  };

  const openConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(true);
  };
  const closeConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
  };
  const confirmDelete = () => {
    if (points.route_stores.length > 1) {
      const pointsArray = points.route_stores;
      setPoints({
        route_stores: [
          ...pointsArray.slice(0, deletedId),
          ...pointsArray.slice(deletedId + 1),
        ],
      });
    }

    closeConfirmDeleteModal();
  };

  const deletePointHandle = (index) => {
    openConfirmDeleteModal();
    setDeletedId(index);
  };

  const beforeSubmit = (values) => {
    const rowValues = values;

    rowValues.route_stores = points.route_stores;
    rowValues.merch_day_id = merchDayId;

    if (editedId) {
      onSubmit({ ...formData, route_stores: [...points.route_stores] });
    } else {
      onSubmit(values);
    }
  };

  const onFormDataChange = (event) => {
    const objFields = event.target.name.split(".");
    console.log(formData);
    if (objFields.length > 1) {
      var newData = {...formData};
      console.log(newData);
      if (!(objFields[0] in newData)){
        newData[objFields[0]] = {};
      }
      newData[objFields[0]][objFields[1]] = event.target.value;
      setFormData(newData);
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      <div>
        <Form
          onSubmit={beforeSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Номер</p>
                <CustomField
                  placeholder="Номер"
                  name="number"
                  value={formData.number}
                  onChange={onFormDataChange}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Регион</p>
                <CustomField
                  placeholder="Region"
                  name="region"
                  value={formData.region}
                  onChange={onFormDataChange}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>BU</p>
                <CustomField
                  placeholder="BU"
                  name="bu"
                  value={formData.bu}
                  onChange={onFormDataChange}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Фамилия и имя лидера</p>
                <CustomField
                  placeholder="Имя и фамилия лидера"
                  name="group_leader.name"
                  value={formData.group_leader?.name}
                  onChange={onFormDataChange}
                />
              </div>
              <div className={classes.input_item}>
                <p className={classes.subtitle}>Телефон лидера</p>
                <CustomField
                  placeholder="Телефон лидера"
                  name="group_leader.phone"
                  value={formData.group_leader?.phone}
                  onChange={onFormDataChange}
                />
              </div>
              {points.route_stores.map((point, index) => (
                <PointBlock
                  key={index}
                  index={index}
                  point={point}
                  onPointFieldChange={onPointFieldChange}
                  deletePointHandle={deletePointHandle}
                />
              ))}
              <Button
                classes={{
                  root: classes.add_btn,
                }}
                variant="outlined"
                type="button"
                onClick={addPointHandle}
                disableElevation
              >
                Добавить точку
              </Button>
              <div className={classes.button_wrapper}>
                <Button
                  classes={{
                    root: classes.cancel_btn,
                  }}
                  variant="contained"
                  type="button"
                  onClick={onCancel}
                  disableElevation
                >
                  Отмена
                </Button>
                <Button
                  classes={{
                    root: classes.submit_btn,
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  disabled={submitting || pristine}
                >
                  Отправить
                </Button>
              </div>
            </form>
          )}
        />
      </div>
      <ModalWindow
        isOpen={showConfirmDeleteModal}
        title={"Удалить точку маршрута"}
      >
        <div className={classes.confirm_modal}>
          <div className={classes.text}>
            Вы действительно хотите удалить точку маршрута?
          </div>
          <div className={classes.btn_wrapper}>
            <Button
              classes={{
                root: classes.cancel_btn,
              }}
              variant="contained"
              type="button"
              onClick={closeConfirmDeleteModal}
              disableElevation
            >
              Отмена
            </Button>
            <Button
              classes={{
                root: classes.submit_btn,
              }}
              variant="contained"
              color="primary"
              type="button"
              onClick={confirmDelete}
              disableElevation
            >
              Удалить
            </Button>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};
