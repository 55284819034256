import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import LoginPage from "../Login/LoginPage";
import Statistics from "../Statistics/Statistics";
import MerchDays from "../MerchDays/MerchDays";
import Map from "../Map/Map";

import { useSelector } from "react-redux";
import { getWithExpiry } from "../../utils/localStorageWithExpiry";
import { MerchRoutes } from "../MerchRoutes/MerchRoutes";

function App() {
  const { pathname } = useLocation();

  const { isAuth } = useSelector((store) => store.login);
  const token = getWithExpiry("token");

  if (!token && pathname !== "/maps/") {
    return <LoginPage />;
  }

  return (
    <Switch>
      <Route exact path="/">
        <Statistics />
      </Route>
      <Route path="/maps">
        <Map />
      </Route>
      <Route path="/merch/:id/routes">
        <MerchRoutes />
      </Route>
      <Route path="/merch">
        <MerchDays />
      </Route>
    </Switch>
  );
}

export default App;
