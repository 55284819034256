import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import MainContainer from "../MainContainer/MainContainer";

import { authMe } from "../../store/actions/loginActions";
import {
  clearCurrentMerchDay,
  createMerchDay,
  fetchMerchDays,
  updateMerchDay,
} from "../../store/actions/merchActions";

import classes from "./MerchDays.module.scss";

import { ModalWindow } from "../UI/ModalWindow/ModalWindow";
import { MerchDayForm } from "./MerchDayForm/MerchDayForm";
import { Link } from "react-router-dom";

const MerchDays = () => {
  const dispatch = useDispatch();
  const { merchDays } = useSelector(({ merch }) => merch);

  React.useEffect(() => {
    dispatch(authMe());
    dispatch(fetchMerchDays());
    dispatch(clearCurrentMerchDay());
  }, []);

  const [openModal, setOpenModal] = React.useState(false);
  const [editedId, setEditedId] = React.useState(null);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    dispatch(clearCurrentMerchDay());
    setEditedId(null);
    setOpenModal(false);
  };

  const onEditHandle = (id) => {
    setEditedId(id);
    handleClickOpenModal();
  };

  const handleSubmit = ({ date, registration_start_date }) => {
    if (editedId) {
      dispatch(updateMerchDay(editedId, date, registration_start_date));
    } else {
      dispatch(createMerchDay(date, registration_start_date));
    }

    dispatch(clearCurrentMerchDay());
    setEditedId(null);
    handleCloseModal();
  };

  const getMerchDayStatus = (dateString) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const date = new Date(dateString).setHours(0, 0, 0, 0);

    if (date > today) {
      return "Запланирован";
    } else if (date < today) {
      return "Завершен";
    } else {
      return "Идет";
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("ru-Ru");
  };

  return (
    <MainContainer>
      <div className={classes.merch}>
        <div className={classes.content}>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleClickOpenModal}
            startIcon={
              <AddIcon
                classes={{
                  root: classes.btn_icon,
                }}
              />
            }
          >
            <span className={classes.btn_text}>Добавить</span>
          </Button>
          {merchDays?.length > 0 ? (
            <section className={classes.table_wrapper}>
              <div className={classes.title}>Мерч дни</div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell width={200} align="center">
                      Дата
                    </TableCell>
                    <TableCell width={230} align="center">
                      Количество маршрутов
                    </TableCell>
                    <TableCell width={250} align="center">
                      Статус
                    </TableCell>
                    <TableCell width={300} align="center">
                      Количество участников
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchDays.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {formatDate(row.date)}
                        </TableCell>
                        <TableCell align="center">{row.routes_count}</TableCell>
                        <TableCell align="center">
                          {getMerchDayStatus(row.date)}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className={classes.flex_cell}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            {row.registered_count}
                            <div className={classes.actions}>
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onEditHandle(row.id);
                                }}
                                classes={{
                                  root: classes.btn,
                                }}
                              >
                                <CreateIcon />
                              </Button>
                              <Link
                                to={`/merch/${row.id}/routes`}
                                classes={{
                                  root: classes.link,
                                }}
                              >
                                <ArrowForwardIcon />
                              </Link>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </section>
          ) : (
            <div className={classes.no_cards}>
              <div className={classes.text}>
                Для добавления мерч дня нажмите кнопку “Добавить”
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalWindow
        title={editedId ? "Редактировать мерч день" : "Новый мерч день"}
        isOpen={openModal}
        handleClose={handleCloseModal}
      >
        <MerchDayForm onSubmit={handleSubmit} editedId={editedId} />
      </ModalWindow>
    </MainContainer>
  );
};

export default MerchDays;
