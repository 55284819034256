import * as types from "../types";

const initialState = {
  loading: false,
  merchDays: [],
  currentMerchDay: {},
  routes: [],
  currentRoute: {},
  regions: [],
  bu: [],
};

export function merchReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case types.FETCH_MERCH_DAYS:
      return {
        ...state,
        merchDays: action.payload,
        routes: [],
      };
    case types.FETCH_MERCH_DAY:
      return {
        ...state,
        currentMerchDay: action.payload,
      };
    case types.FETCH_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case types.FETCH_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
      };
    case types.FETCH_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case types.FETCH_BU:
      return {
        ...state,
        bu: action.payload,
      };
    case types.CLEAR_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: {},
      };
    case types.CLEAR_CURRENT_MERCH_DAY:
      return {
        ...state,
        currentMerchDay: {},
      };
    case types.UPLOAD_FILE_ROUTES:
      return {
        ...state,
        currentRoute: action.payload,
      };
    default:
      return state;
  }
}
