import axios from "axios";
import * as types from "../types";

export const fetchMerchDays = () => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get("/merch_days/")
      .then((response) =>
        dispatch({ type: types.FETCH_MERCH_DAYS, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createMerchDay = (date, registration_start_date) => {
  return async (dispatch) => {
    const body = { date, registration_start_date };

    await axios
      .post("/merch_days/", body)
      .then((response) => dispatch(fetchMerchDays()))
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchMerchDay = (id) => {
  return async (dispatch) => {
    await axios
      .get(`/merch_days/${id}`)
      .then((response) =>
        dispatch({ type: types.FETCH_MERCH_DAY, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateMerchDay = (id, date, registration_start_date) => {
  return async (dispatch) => {
    const body = { date, registration_start_date };

    await axios
      .patch(`/merch_days/${id}`, body)
      .then((response) => dispatch(fetchMerchDays()))
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchRoutes = (merchDayId, region, bu) => {
  let query = "";

  if (region && bu) {
    query = `?region=${region}&bu=${bu}`;
  } else if (!region && bu) {
    query = `?bu=${bu}`;
  } else if (region && !bu) {
    query = `?region=${region}`;
  }

  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });

    await axios
      .get(`/merch_days/${merchDayId}/routes${query}`)
      .then((response) =>
        dispatch({ type: types.FETCH_ROUTES, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const createRoute = (merchDayId, body) => {
  return async (dispatch) => {
    await axios
      .post(`/merch_days/${merchDayId}/routes`, body)
      .then((response) => {
        dispatch(fetchRoutes(merchDayId));
        dispatch(fetchRegions(merchDayId));
        dispatch(fetchBU(merchDayId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchRoute = (merchDayId, routeId) => {
  return async (dispatch) => {
    await axios
      .get(`/merch_days/${merchDayId}/routes/${routeId}`)
      .then((response) =>
        dispatch({ type: types.FETCH_ROUTE, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      });
  };
};

export const clearCurrentRoute = () => ({ type: types.CLEAR_CURRENT_ROUTE });
export const clearCurrentMerchDay = () => ({
  type: types.CLEAR_CURRENT_MERCH_DAY,
});

export const deleteRoute = (merchDayId, routeId) => {
  return async (dispatch) => {
    await axios
      .delete(`/merch_days/${merchDayId}/routes/${routeId}`)
      .then((response) => {
        dispatch(fetchRoutes(merchDayId));
        dispatch(fetchRegions(merchDayId));
        dispatch(fetchBU(merchDayId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRoute = (merchDayId, routeId, body) => {
  return async (dispatch) => {
    await axios
      .patch(`/merch_days/${merchDayId}/routes/${routeId}`, body)
      .then((response) => {
        dispatch(fetchRoutes(merchDayId));
        dispatch(fetchRegions(merchDayId));
        dispatch(fetchBU(merchDayId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const uploadFileRoutes = (merchDayId, file) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_LOADING, payload: true });
    await axios
      .post(`/merch_days/${merchDayId}/import_routes`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => dispatch({ type: types.UPLOAD_FILE_ROUTES }))
      .then((response) => {
        dispatch(fetchRoutes(merchDayId));
        dispatch(fetchRegions(merchDayId));
        dispatch(fetchBU(merchDayId));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch({ type: types.SET_LOADING, payload: false }));
  };
};

export const fetchRegions = (merchDayId) => {
  return async (dispatch) => {
    await axios
      .get(`/merch_days/${merchDayId}/regions`)
      .then((response) =>
        dispatch({ type: types.FETCH_REGIONS, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchBU = (merchDayId) => {
  return async (dispatch) => {
    await axios
      .get(`/merch_days/${merchDayId}/bu`)
      .then((response) =>
        dispatch({ type: types.FETCH_BU, payload: response.data })
      )
      .catch((err) => {
        console.log(err);
      });
  };
};
